/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://62r5tja3grdx5cxxg56bwqxwcy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ce362x4krvdy5msxivihrutmjm",
    "aws_cognito_identity_pool_id": "us-east-2:3e615a0b-cb88-44c1-b73e-143b18907fcf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_hKHR9oXqF",
    "aws_user_pools_web_client_id": "vs231qud0hpedbmto8tegm3re",
    "oauth": {},
    "aws_user_files_s3_bucket": "irc41clientimages-bucket224332-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
